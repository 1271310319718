import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './Home.css'
import NashiLogo1 from '../assets/Nashi.png'
import DavinesLogo1 from '../assets/davinesLogo.jpg'
import Slide1 from '../assets/SLIDE8.PNG'
import Slide2 from '../assets/SLIDE228.PNG'
import Slide3 from '../assets/SLIDE15.PNG'
import Slide4 from '../assets/SLIDE12.PNG'
import Slide5 from '../assets/SLIDE19.PNG'

const Home = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [slides, setSlides] = useState([Slide1, Slide2, Slide3, Slide4, Slide5])
  const [isSalonOpen, setIsSalonOpen] = useState(false)

  useEffect(() => {
    const updateSlides = () => {
      const width = window.innerWidth
      if (width <= 350) {
        setSlides([Slide3, Slide4, Slide5])
      } else if (width <= 450) {
        setSlides([Slide2, Slide3, Slide4, Slide5])
      } else if (width < 970) {
        setSlides([Slide3, Slide4, Slide5])
      } else {
        setSlides([Slide1, Slide2, Slide3, Slide4, Slide5])
      }
    }

    updateSlides()
    window.addEventListener('resize', updateSlides)

    return () => window.removeEventListener('resize', updateSlides)
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length)
    }, 2000)
    return () => clearInterval(interval)
  }, [slides.length])

  return (
    <div>
      <div className="slideshow-container">
        <img
          src={slides[currentSlide]}
          alt="Slideshow"
          className="slideshow-image"
        />
      </div>
      <div className="home-container">
        <p className="favorite-text">
          SELECTAȚI PRODUSELE FAVORITE DIN MENIUL MAI JOS
        </p>
        <div className="image-container">
          <Link to="/Davines">
            <img src={DavinesLogo1} alt="Логотип Davines" />
          </Link>
          <Link to="/Nashi">
            <img src={NashiLogo1} alt="Логотип Nashi" />
          </Link>
        </div>
      </div>
      <p className="description-text">
        Formulele produsei favorizează utilizarea ingredientelor de origine
        naturală, sunt generate cu energie electrică regenerabilă și ambalate
        minimizând impactul asupra mediului, inspirate de idealul de Frumusețe
        Durabilă ca o unitate inseparabilă între
        <p>Frumusețe și Bine.</p>
      </p>
      {/* New Popup Section for Mobile */}
      <div className="salon-popup">
        <button onClick={() => setIsSalonOpen(!isSalonOpen)}>
          {isSalonOpen ? 'contactează-ne ▲' : 'contactează-ne ▼'}
        </button>
        {isSalonOpen && (
          <div className="salon-content">
            <p className="small-text">Luni-sâmbătă de la 9:00 pînă la 19:30</p>
            <p className="phone-number">+373 78 757 585</p>
            <p className="small-text">
              <a
                href="https://www.google.com/maps/place/Yellow+boutique+beauty+studio/@47.0491161,28.868135,18z/data=!4m6!3m5!1s0x40c97ded744118ab:0xdd30463744de6e52!8m2!3d47.0491183!4d28.8686153!16s%2Fg%2F11fq3ds2vs?entry=ttu&g_ep=EgoyMDI0MTAwOS4wIKXMDSoASAFQAw%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                str. Nicolae Dimo, 21/1
              </a>
            </p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Home
